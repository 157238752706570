import './about-page.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import bannerImage1 from '../assets/aboutPage-banner-1-img.png';
import bannerImage2 from '../assets/aboutPage-banner-2-img.png';
import bannerImage3 from '../assets/aboutPage-banner-3-img.png';
import bannerImage4 from '../assets/aboutPage-banner-4-img.png';

import carouselImage1 from '../assets/aboutPage-carousel-1-img.png';
import carouselImage2 from '../assets/aboutPage-carousel-2-img.png';
import carouselImage3 from '../assets/aboutPage-carousel-3-img.png';
import carouselImage4 from '../assets/aboutPage-carousel-4-img.png';

function AboutPage() {
    const bannerText1 = "Somos uma empresa com anos de experiência na produção e instalação de placas de gesso.";
    const bannerText2 = "Nosso compromisso é transformar ambientes, criando espaços que toquem as emoções de nossos clientes.";
    const bannerText3 = "Com foco na qualidade, durabilidade e estética.";
    const bannerText4 = "Trabalhamos com dedicação e atenção aos detalhes para entregar resultados que unem funcionalidade e beleza.";
    const bannerText5 = "Quer ver alguns dos nossos projetos?\n Confira abaixo alguns dos nossos trabalhos e inspire-se com\n as transformações que fizemos em diversos ambientes!";

    return (
        <div className='about-page-container'>
            <div className='about-page-top-container'>
                <div className='about-page-banner-container'>
                    <img src={bannerImage1} alt='' />
                    <p>{bannerText1}</p>
                </div>
                <div className='about-page-banner-container'>
                    <img src={bannerImage2} alt='' />
                    <p>{bannerText2}</p>
                </div>
                <div className='about-page-banner-container'>
                    <img src={bannerImage3} alt='' />
                    <p>{bannerText3}</p>
                </div>
                <div className='about-page-banner-container'>
                    <img src={bannerImage4} alt='' />
                    <p>{bannerText4}</p>
                </div>
            </div>
            <div className='about-page-middle-container'>
                <p>{bannerText5}</p>
            </div>
            <div className='about-page-bottom-container'>
                <Swiper
                    modules={[Navigation]}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    navigation
                >
                    <SwiperSlide>
                        <img className='about-carousel-image-1' src={carouselImage1} alt='' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img className='about-carousel-image-2' src={carouselImage2} alt='' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img className='about-carousel-image-3' src={carouselImage3} alt='' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img className='about-carousel-image-4' src={carouselImage4} alt='' />
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    );
}

export default AboutPage;