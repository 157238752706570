import './faq.css';

import { useState } from 'react';

import faqButton from '../../assets/faq-button.png';
import faqListItem from '../../assets/faq-list-item-img.png';

function FAQ() {
    // Estado para controlar a visibilidade da lista
    const [showList, setShowList] = useState(false);

    // Estado para controlar qual item está expandido
    const [expandedItem, setExpandedItem] = useState(null);

    const list = [
        {
            "title": "QUAL A DURABILIDADE DAS PLACAS DE GESSO?",
            "paragraph": "As placas de gesso, também chamadas de placas de drywall, têm uma durabilidade bastante longa quando instaladas e mantidas corretamente. Em geral, elas podem durar entre 20 a 30 anos ou mais"
        },
        {
            "title": "POSSO PINTAR AS PLACAS DE GESSO?",
            "paragraph": "Sim, você pode pintar as placas de gesso, e isso é uma prática comum para dar acabamento e estética ao ambiente. Além de aumentar a vida útil das placas."
        },
        {
            "title": "QUANTO TEMPO LEVA PARA INSTALAR AS PLACAS DE GESSO?",
            "paragraph": "O tempo necessário para instalar placas de gesso (drywall) pode variar dependendo de vários fatores, como o tamanho da área a ser coberta, o tipo de instalação (como paredes ou tetos) e a complexidade do projeto."
        },
        {
            "title": "PRECISO DE REFORMAS NO LOCAL ANTES DE INSTALAR AS PLACAS DE GESSO?",
            "paragraph": "O tempo necessário para instalar placas de gesso (drywall) pode variar dependendo de vários fatores, como o tamanho da área a ser coberta, o tipo de instalação (como paredes ou tetos) e a complexidade do projeto."
        },
        {
            "title": "O PREÇO VARIA DE ACORDO COM O TIPO DE PLACA OU O TAMANHO DO PROJETO?",
            "paragraph": "Sim, o preço de instalação das placas de gesso (drywall) pode variar tanto em função do tipo de placa quanto do tamanho e complexidade do projeto."
        },
        {
            "title": "É POSSÍVEL INSTALAR ILUMINAÇÃO EMBUTIDA NAS PLACAS DE GESSO?",
            "paragraph": "Sim, é totalmente possível instalar iluminação embutida nas placas de gesso (drywall), e isso é uma prática comum em projetos de iluminação decorativa e ambiental. A instalação de spots, dimerização de luz e luminárias embutidas pode ser feita de maneira segura e eficiente nas paredes ou tetos de drywall. No entanto, como envolve fia"
        },
        {
            "title": "AS PLACAS DE GESSO SUPORTAM PESO? POSSO PENDURAR PRATELEIRAS OU OBJETOS PESADOS?",
            "paragraph": "As placas de gesso (drywall) têm algumas limitações em termos de capacidade de carga, e por isso, é importante considerar alguns cuidados ao pendurar prateleiras ou objetos pesados. Em geral, o gesso por si só não é um material muito forte para suportar peso direto, especialmente se o objeto for muito pesado. No entanto, é possível pendurar objetos de maneira segura no drywall, desde que sejam tomados os cuidados adequados e os fixadores certos sejam utilizados."
        }
    ];

    // Função para alternar a visibilidade da lista
    const toggleList = () => {
        setShowList(!showList);
    };

    // Função para alternar a visibilidade do conteúdo de um item específico
    const toggleItem = (index) => {
        // Se o mesmo item for clicado novamente, ele será fechado
        setExpandedItem(expandedItem === index ? null : index);
    };

    return (
        <div className='faq-container'>
            <div onClick={toggleList} className='faq-button-container'>
                <img src={faqButton} alt='' />
                <p>Dúvidas frequentes</p>
            </div>
            {/* Renderiza a lista apenas se showList for true */}
            {showList && (
                <ul className='faq-list-container'>
                    {list.map((item, index) => (
                        <li key={index} onClick={() => toggleItem(index)} className='faq-list-item-container'>
                            <h2><img src={faqListItem} alt='' />{item.title}</h2>
                            {/* Renderiza o conteúdo adicional se o item estiver expandido */}
                            {expandedItem === index && (
                                <div className='faq-list-item-paragraph-container'>
                                    <p>{item.paragraph}</p>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default FAQ;
