import './contact-page.css';

import ContactCard from "../components/ContactCard";

import contactWhatsappImage from '../assets/contactPage-whatsapp-img.png';
import contactFacebookImage from '../assets/contactPage-facebook-img.png';
import contactEmailImage from '../assets/contactPage-email-img.png';

function ContactPage() {
    const contactWhatsappDescription = "Se você tiver mais dúvidas ou quiser solicitar um orçamento, é só clicar no botão abaixo para entrar em contato conosco!";
    const contactFacebookDescription = "Gostaria de ver alguns dos nossos projetos anteriores? Clique no botão abaixo para acessar nosso Facebook e conferir fotos dos nossos trabalhos.";
    const contactEmailDescription = "Se preferir, também pode entrar em contato conosco por e-mail.";

    const contactWhatsappLinkText = "(53) 98415-3107";
    const contactFacebookLinkText = "GB GESSO 3D";
    const contactEmailLinkText = "gbgesso.pelotas@gmail.com";

    const contactWhatsappLinkPath = "https://wa.me/5553984153107";
    const contactFacebookLinkPath = "https://www.facebook.com/profile.php?id=100063765422202";
    const contactEmailLinkPath = "mailto:gbgesso.pelotas@gmail.com";

    const cardProps = [
        {
            "image": contactWhatsappImage,
            "description": contactWhatsappDescription,
            "linkText": contactWhatsappLinkText,
            "linkPath": contactWhatsappLinkPath
        },
        {
            "image": contactFacebookImage,
            "description": contactFacebookDescription,
            "linkText": contactFacebookLinkText,
            "linkPath": contactFacebookLinkPath
        },
        {
            "image": contactEmailImage,
            "description": contactEmailDescription,
            "linkText": contactEmailLinkText,
            "linkPath": contactEmailLinkPath
        }
    ];

    return (
        <div className='contact-page-container'>
            <div className='contact-page-title-container'>
                <h2>COMO VOCÊ PREFERE FALAR COM A GENTE?</h2>
            </div>
            <div className='contact-page-card-container'>
                {cardProps.map((card) => {
                    return (
                        <ContactCard 
                            image={card.image} 
                            description={card.description} 
                            linkText={card.linkText}
                            linkPath={card.linkPath}
                        />
                    )
                })}
                {/* <ContactCard
                    image={contactWhatsappImage}
                    description={contactWhatsappDescription}
                    linkText={contactWhatsappLinkText}
                />
                <ContactCard
                    image={contactFacebookImage}
                    description={contactFacebookDescription}
                    linkText={contactFacebookLinkText}
                />
                <ContactCard
                    image={contactEmailImage}
                    description={contactEmailDescription}
                    linkText={contactEmailLinkText}
                /> */}
            </div>
        </div>
    );
}

export default ContactPage;