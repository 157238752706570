import './home-page.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';

import Button from "../components/Button";

import carouselImage1 from '../assets/header-carousel-1-img.png';
import carouselImage2 from '../assets/header-carousel-2-img.png';
import carouselImage3 from '../assets/header-carousel-3-img.png';
import carouselImage4 from '../assets/header-carousel-4-img.png';

import buttonAboutImage from '../assets/homepage-button-about-img.png';
import buttonContactImage from '../assets/homepage-button-contact-img.png';
import buttonProductsImage from '../assets/homepage-button-products-img.png';

import buttonAboutHoveredImage from '../assets/homepage-button-about-hover-img.png';
import buttonContactHoveredImage from '../assets/homepage-button-contact-hover-img.png';
import buttonProductsHoveredImage from '../assets/homepage-button-products-hover-img.png';

const buttonAboutTitle = "SOBRE NÓS";
const buttonContactTitle = "CONTATOS";
const buttonProductsTitle = "PRODUTOS";

function HomePage() {
    return (
        <div className='homepage-container'>
            <div className='homepage-swiper-container'>
                <Swiper
                    modules={[Navigation]}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    navigation
                >
                    <SwiperSlide>
                        <div className='image-container'>
                            <img className='carousel-image-1' src={carouselImage1} alt='' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='image-container'>
                            <img className='carousel-image-2' src={carouselImage2} alt='' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='image-container'>
                            <img className='carousel-image-3' src={carouselImage3} alt='' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='image-container'>
                            <img className='carousel-image-4' src={carouselImage4} alt='' />
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className='homepage-buttons-container'>
                <Button
                    image={buttonAboutImage}
                    hoveredImage={buttonAboutHoveredImage}
                    title={buttonAboutTitle}
                    path='/sobre'
                />
                <Button
                    image={buttonContactImage}
                    hoveredImage={buttonContactHoveredImage}
                    title={buttonContactTitle}
                    path='/contato'
                />
                <Button
                    image={buttonProductsImage}
                    hoveredImage={buttonProductsHoveredImage}
                    title={buttonProductsTitle}
                    path='/produtos'
                />
            </div>
        </div>
    );
}

export default HomePage;