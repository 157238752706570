import './contact-card.css';

function ContactCard({ image, description, linkText, linkPath }) {
    return (
        <div className='contact-card-container'>
            <div className='contact-card-image-container'>
                <img src={image} alt='' />
            </div>
            <div className='contact-card-text-container'>
                <p>{description}</p>
                <a href={linkPath}>
                    <h2>{linkText}</h2>
                </a>
            </div>
        </div>
    );
}

export default ContactCard;