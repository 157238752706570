import { Link } from 'react-router-dom';

import './header.css';

import mainLogoImage from '../../assets/header-gbgesso-logo.png';
import aboutButtonImage from '../../assets/header-about-button.png';
import contactButtonImage from '../../assets/header-contact-button.png';
import productsButtonImage from '../../assets/header-products-button.png';

const headerText = 'GB GESSO 3D';
const headerSubtext = 'REVESTIMENTO DE INTERIORES';
const bottomText = 'PLACAS DE GESSO 3D | INSTALAÇÃO PROFISSIONAL | REVESTIMENTOS DE GESSO | ACABAMENTO E PINTURA';

function Header() {
    return (
        <div className='header-container'>
            <div className='header-top-container'>
                <Link to='/'>
                    <div className='header-left-container'>
                        <img src={mainLogoImage} alt='' />
                        <div>
                            <h1>{headerText}</h1>
                            <h2>{headerSubtext}</h2>
                        </div>
                    </div>
                </Link>
                <div className='header-right-container'>
                    <Link to='/sobre'>
                        <img src={aboutButtonImage} alt='' />
                    </Link>
                    <Link to='/contato'>
                        <img src={contactButtonImage} alt='' />
                    </Link>
                    <Link to='/produtos'>
                        <img src={productsButtonImage} alt='' />
                    </Link>
                </div>
            </div>

            <div className='header-bottom-container'>
                <p>{bottomText}</p>
            </div>
        </div>
    );
}

export default Header;