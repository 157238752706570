import './card.css';

function ContactCard({ image, title, subtitle, value }) {
    return (
        <div className='card-container'>
            <div className='card-left-container'>
                <img src={image} alt='' />
            </div>
            <div className='card-right-container'>
                <div className='card-right-top-container'>
                    <h2>{title}</h2>
                    <p>{subtitle}</p>
                </div>
                <div className='card-right-bottom-container'>
                    <p>Valor por unidade:</p>
                    <h2>{value}</h2>
                </div>
            </div>
        </div>
    );
}

export default ContactCard;