import { BrowserRouter, Route, Routes } from "react-router-dom";

import HomePage from './routes/HomePage';
import ContactPage from './routes/ContactPage';
import AboutPage from './routes/AboutPage';
import ProductsPage from './routes/ProductsPage';
import Header from './components/Header';
import Footer from "./components/Footer";
import FAQ from "./components/FAQ";

function App() {
    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/contato' element={<ContactPage />} />
                <Route path='/sobre' element={<AboutPage />} />
                <Route path='/produtos' element={<ProductsPage />} />
            </Routes>
            <FAQ />
            <Footer />
        </BrowserRouter>
    );
}

export default App;