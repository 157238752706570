import { useState } from 'react';
import './button.css';

import { Link } from 'react-router-dom';

function Button({ image, hoveredImage, title, path }) {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Link
            to={path}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className='button-container'>
                <h2>{title}</h2>
                <img src={isHovered ? hoveredImage : image} alt='' />
            </div>
        </Link>
    );
}

export default Button;