import './products-page.css';

import Card from "../components/Card";

import productsImage from '../assets/productspage-products-img.png';

import productsImage1 from '../assets/productspage-product-1-img.png';
import productsImage2 from '../assets/productspage-product-2-img.png';
import productsImage3 from '../assets/productspage-product-3-img.png';
import productsImage4 from '../assets/productspage-product-4-img.png';
import productsImage5 from '../assets/productspage-product-5-img.png';
import productsImage6 from '../assets/productspage-product-6-img.png';
import productsImage7 from '../assets/productspage-product-7-img.png';
import productsImage8 from '../assets/productspage-product-8-img.png';
import productsImage9 from '../assets/productspage-product-9-img.png';

import placeholderProductImage from '../assets/productpage-placeholder-img.png';

import servicesImage1 from '../assets/productspage-services-1-img.png';
import servicesImage2 from '../assets/productspage-services-2-img.png';
import servicesImage3 from '../assets/productspage-services-3-img.png';
import servicesImage4 from '../assets/productspage-services-4-img.png';
import servicesImage5 from '../assets/productspage-services-5-img.png';
import servicesImage6 from '../assets/productspage-services-6-img.png';
import servicesImage7 from '../assets/productspage-services-7-img.png';

function ProductsPage() {
    const placeholderTitle = "MODELO DA PLACA";
    const placeholderSubTitle = "Tamanhos disponíveis: 20x20 | 30x30 | 40x40";
    const placeholderValue = "R$20,00";

    const productTitle1 = "DIAMANTE";
    const productSubTitle1 = "Tamanhos disponíveis: 40x40";
    const productValue1 = "R$8,00";

    const productTitle2 = "ALMOFADA";
    const productSubTitle2 = "Tamanhos disponíveis: 28x28";
    const productValue2 = "R$3,00";

    const productTitle3 = "JARDIM DO ÉDEN";
    const productSubTitle3 = "Tamanhos disponíveis: 33x33";
    const productValue3 = "R$4,00";

    const productTitle4 = "PÉTALA";
    const productSubTitle4 = "Tamanhos disponíveis: 40x40";
    const productValue4 = "R$8,00";

    const productTitle5 = "TAQUEADA";
    const productSubTitle5 = "Tamanhos disponíveis: 29x29";
    const productValue5 = "R$3,00";

    const productTitle6 = "TIJOLO";
    const productSubTitle6 = "Tamanhos disponíveis: 30x45";
    const productValue6 = "R$10,00";

    const productTitle7 = "MARESIA";
    const productSubTitle7 = "Tamanhos disponíveis: 40x40";
    const productValue7 = "R$8,00";

    const productTitle8 = "ORION";
    const productSubTitle8 = "Tamanhos disponíveis: 30x45";
    const productValue8 = "R$10,00";

    const productTitle9 = "DIAMANTE";
    const productSubTitle9 = "Tamanhos disponíveis: 28x28";
    const productValue9 = "R$3,00";

    const productTitle10 = "DIAMANTE";
    const productSubTitle10 = "Tamanhos disponíveis: 40x40";
    const productValue10 = "R$8,00";

    return (
        <div className='products-page-container'>
            <div className='products-page-title-container'>
                <img src={productsImage} alt='' />
                <h2>NOSSOS PRODUTOS</h2>
            </div>
            <div className='products-page-cards-container'>
                <div>
                    <Card
                        image={productsImage1}
                        title={productTitle1}
                        subtitle={productSubTitle1}
                        value={productValue1}
                    />
                    <Card
                        image={productsImage2}
                        title={productTitle2}
                        subtitle={productSubTitle2}
                        value={productValue2}
                    />
                    <Card
                        image={productsImage3}
                        title={productTitle3}
                        subtitle={productSubTitle3}
                        value={productValue3}
                    />
                    <Card
                        image={productsImage4}
                        title={productTitle4}
                        subtitle={productSubTitle4}
                        value={productValue4}
                    />
                    <Card
                        image={productsImage5}
                        title={productTitle5}
                        subtitle={productSubTitle5}
                        value={productValue5}
                    />
                </div>
                <div>
                    <Card
                        image={productsImage6}
                        title={productTitle6}
                        subtitle={productSubTitle6}
                        value={productValue6}
                    />
                    <Card
                        image={productsImage7}
                        title={productTitle7}
                        subtitle={productSubTitle7}
                        value={productValue7}
                    />
                    <Card
                        image={productsImage8}
                        title={productTitle8}
                        subtitle={productSubTitle8}
                        value={productValue8}
                    />
                    <Card
                        image={productsImage9}
                        title={productTitle9}
                        subtitle={productSubTitle9}
                        value={productValue9}
                    />
                    <Card
                        image={productsImage9}
                        title={productTitle10}
                        subtitle={productSubTitle10}
                        value={productValue10}
                    />
                </div>
            </div>
            <div className='products-page-services-top-container'>
                <div className='products-page-services-title-container'>
                    <h2>SERVIÇOS</h2>
                </div>
                <div className='products-page-service-container'>
                    <img src={servicesImage1} alt='' />
                    <h3>INSTALAÇÃO PROFISSIONAL</h3>
                </div>
                <div className='products-page-service-container'>
                    <img src={servicesImage2} alt='' />
                    <h3>REVESTIMENTO DE GESSO</h3>
                </div>
                <div className='products-page-service-container'>
                    <img src={servicesImage3} alt='' />
                    <h3>ACABAMENTO E PINTURA</h3>
                </div>
                <div className='products-page-service-container'>
                    <img src={servicesImage4} alt='' />
                    <h3>IMPERMEABILIZAÇÃO</h3>
                </div>
            </div>
            <div className='products-page-services-bottom-container'>
                <div className='products-page-services-title-container'>
                    <h2>SERVIÇOS | </h2>
                    <h2>ILUMINAÇÃO</h2>
                </div>
                <div className='products-page-service-container'>
                    <img src={servicesImage5} alt='' />
                    <h3>ILUMINAÇÃO COM FITA DE LED</h3>
                </div>
                <div className='products-page-service-container'>
                    <img src={servicesImage6} alt='' />
                    <h3>ILUMINAÇÃO COM SPOTS</h3>
                </div>
                <div className='products-page-service-container'>
                    <img src={servicesImage7} alt='' />
                    <h3>SANCA</h3>
                </div>
            </div>
        </div>
    );
}

export default ProductsPage;