import './footer.css';

import { Link } from "react-router-dom";

import emailImage from '../../assets/footer-email-img.png';
import contactImage from '../../assets/footer-contact-img.png';
import facebookImage from '../../assets/footer-facebook-img.png';

import gbgessoLogo from '../../assets/footer-gbgesso-logo.png';

import instagramLargeImage from '../../assets/footer-instagram-large-img.png';
import facebookLargeImage from '../../assets/footer-facebook-large-img.png';
import whatsappLargeImage from '../../assets/footer-whatsapp-large-img.png';
import emailLargeImage from '../../assets/footer-email-large-img.png';

import hipercardImage from '../../assets/footer-hipercard-img.png';
import mastercardImage from '../../assets/footer-mastercard-img.png';
import banricardImage from '../../assets/footer-banricard-img.png';
import visaImage from '../../assets/footer-visa-img.png';

const navLinks = [
    {
        "name": "HOME",
        "path": "/"
    },
    {
        "name": "SOBRE",
        "path": "/sobre"
    },
    {
        "name": "CONTATOS",
        "path": "/contato"
    },
    {
        "name": "PRODUTOS",
        "path": "/produtos"
    }
];

const contactLinks = [
    {
        "name": "gbgesso.pelotas@gmail.com",
        "path": "mailto:gbgesso.pelotas@gmail.com",
        "image": emailImage,
        "id": "1"
    },
    {
        "name": "(53) 98415-3107",
        "path": "https://wa.me/5553984153107",
        "image": contactImage,
        "id": "2"
    },
    {
        "name": "GB GESSO 3D",
        "path": "https://www.facebook.com/profile.php?id=100063765422202",
        "image": facebookImage,
        "id": "3"
    },
];

const redesLinks = [
    // {
    //     "path": "/",
    //     "image": instagramLargeImage,
    // },
    {
        "path": "https://www.facebook.com/profile.php?id=100063765422202",
        "image": facebookLargeImage,
    },
    {
        "path": "https://wa.me/5553984153107",
        "image": whatsappLargeImage,
    },
    {
        "path": "mailto:gbgesso.pelotas@gmail.com",
        "image": emailLargeImage,
    }
];

const linksTitle = "GB GESSO 3D";
const atendimentoTitle = "ATENDIMENTO";
const atendimentoParagraph = "Segunda à Sexta das 9h as 17h\n Sábado das 9h às 13h";
const produtosTitle = "NOSSOS PRODUTOS";
const produtosParagraph = "Revestimento de Gesso | Acabamento e Pintura | Instalação Profissional | Placas de Gesso 3D";
const contatosTitle = "CONTATOS";

function Footer() {
    return (
        <div className='footer-container'>
            <div className='footer-left-container'>
                <div className='footer-links-container'>
                    <h2>{linksTitle}</h2>
                    {navLinks.map((link) => {
                        return (
                            <Link to={link.path} key={link.name}>
                                {link.name}
                            </Link>
                        )
                    })}
                </div>
                <div className='footer-atendimento-container'>
                    <h2>{atendimentoTitle}</h2>
                    <p>{atendimentoParagraph}</p>
                </div>
                <div className='footer-produtos-container'>
                    <h2>{produtosTitle}</h2>
                    <p>{produtosParagraph}</p>
                </div>
                <div className='footer-contatos-container'>
                    <h2>{contatosTitle}</h2>
                    {contactLinks.map((link) => {
                        return (
                            <Link to={link.path} key={link.id}>
                                <img src={link.image} alt='' />
                                <p>{link.name}</p>
                            </Link>
                        )
                    })}
                </div>
            </div>
            <div className='footer-right-container'>
                <div className='footer-logo-container'>
                    <img src={gbgessoLogo} alt='' />
                </div>
                <div className='footer-redes-container'>
                    <h2>NOSSAS REDES SOCIAIS</h2>
                    {redesLinks.map((link) => {
                        return (
                            <a href={link.path}>
                                <img src={link.image} alt='' />
                            </a>
                        )
                    })}
                </div>
                <div className='footer-pagamento-container'>
                    <h2>FORMAS DE PAGAMENTO</h2>
                    <div>
                        <img src={hipercardImage} alt='' />
                        <img src={mastercardImage} alt='' />
                        <img src={banricardImage} alt='' />
                        <img src={visaImage} alt='' />
                    </div>
                </div>
                {/* <h2>PELOTAS - RS</h2> */}
            </div>
        </div>
    );
}

export default Footer;